<div id="loading-wrapper" *ngIf="isLodaing">
  <!-- <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div> -->
  <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>
<div class="container">
  <div class="card cardadjust" style="width: 22rem; height: 30rem;">
    <form [formGroup]="reset">
      <h4>Reset Password Page</h4>
      <p style="font-size:15px ;">Type your password correctly</p>
      <div class="form-group">
        <label for="password" class="control-label">Enter your old Password</label>
        <input name="oldPassword" formControlName="oldPassword" type="password" class="form-control"
          placeholder="Old Password">
        <span class="text-danger" *ngIf="resetForm.oldPassword.touched && resetForm.oldPassword.errors?.required">Old
          password is required</span>
      </div>
      <div class="form-group">
        <label>Password</label>
        <input type="password" class="form-control" formControlName="password" placeholder="Password">
        <span class="text-danger" *ngIf="resetForm.password.touched && resetForm.password.errors?.required"> Password is
          required</span>
      </div>
      <div class="form-group">
        <label>Confirm Password</label>
        <input type="password" formControlName="cPassword" class="form-control" placeholder="Confirm Password">
        <span class="text-danger" *ngIf="resetForm.cPassword.touched && resetForm.cPassword.errors?.required"> Password
          is required</span>
      </div>
      <button (click)="gotohome()" class="btn-sm" type="button" class="btn btn-light">Cancel</button>
      <button
        class="btn-sm" style="margin-left: 90px;" (click)="submit()" type="submit" class="btn btn-primary"
        [disabled]="!reset.valid">Submit</button>
    </form>
  </div>
</div>