<!-- <div style="width: 50; height: 50; border-width: 1;"></div> -->
<!-- <div class="row"> -->
    <div class="row mt-4">
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
        <!-- <button (click)="redirect()" class="btn btn-warning">Home</button> -->
        <button (click)="redirect()" class="btn btn-success ml-5"><i class="icon-arrow-left"></i>&nbsp;Back to Home</button>
    </div>
    <div class="col-lg-10 col-md-10 col-sm-10 col-10">
        <div class="text-center" style="margin: 10px;">
            <!-- <ul style="display: inline-block; list-style-type:none; color: white;"> -->
                <ul style="display: inline-block; list-style-type:none;">
                <li style="padding-right: 48px;"> <img class="markerIndication"
                        src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png"
                        alt=""> &nbsp;ONGOING</li>
            </ul>
            <!-- <ul style="display: inline-block; list-style-type:none; color: white;"> -->
                <ul style="display: inline-block; list-style-type:none;">
                <li> <img class="markerIndication"
                        src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png"
                        alt="">&nbsp;&nbsp;&nbsp;COMPLETED</li>
            </ul>
        </div>
    </div>
</div>
<!-- <div class="map" #map id="map" style="width: 97%; height: 70vh;position: absolute;z-index: 1;"></div> -->
<div class="map" #map id="map" style="width: 95%;height: 80vh;position: absolute;z-index: 1;margin-left: 50px;margin-top: 10px;border-radius: 10px;border: 1px solid #eae8e8;box-shadow: 0px 10px 20px #cccccc;"></div>