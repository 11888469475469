<!-- Loading starts -->
<div id="loading-wrapper" *ngIf="isLodaing">
    <!-- <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div> -->
    <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<!-- Loading ends -->


<!-- Container fluid start -->
<div class="container-fluid p-0" *ngIf="!isLodaing">

    <app-project-navbar projectId='{{projectId}}' activePage='projectDetails'></app-project-navbar>

    <!-- *************
        ************ Main container start *************
    ************* -->
    <div class="main-container">

        <!-- Page header start -->
        <div class="page-header">
            <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-6 text-center">
                    <p class="fontmobile" style="text-align: left;" title="{{projectDetails.name}}">
                        {{projectDetails.name}} </p>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-2 col-sm-4 col-3 text-center pull-right "
                    style="padding-right:0px;">
                    <!-- <div class="blink_me float-right">
                            <a href="javascript:;" routerLink="/dohua-geotag/{{projectDetails.id}}" style="font-weight: bold ; color:yellow; font-size: 10px;">
                                <i class="icon-eye"></i> &nbsp; View in DoHuA-GEOTAG
                            </a>
                        </div> -->
                </div>
                <div class="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-3"
                    *ngIf="user.accessKeyword == projectDetails.accessKeyword">
                    <a style="color: white !important;" href="#" data-toggle="modal" data-target="#editProject"
                        data-placement="top" title="" data-original-title="Edit Project">
                        <i class="icon-pencil"></i> &nbsp; Edit
                    </a>
                </div>
            </div>
        </div>
        <br>
        <br>
        <!-- Page header end -->

        <!-- Page header start -->
        <!-- <div class="page-header ">
            <div class="row">
                <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-12 text-center">
                    <h5 style="text-align: center;">{{projectDetails.name}}</h5>
                </div>
                <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12 text-center">
                    <span style="padding:5px 10px 5px 10px;border-radius:15px;background-color: aliceblue;color: aquamarine;"><a href="#" data-toggle="modal" data-target="#editProject" data-placement="top" title="" data-original-title="EditProject">
                    <i class="icon-pencil"></i> Edit</a></span>
                </div>
            </div>
            
        </div> -->
        <!-- Page header end -->

        <!-- Content wrapper start -->

        <div class="content-wrapper">

            <!-- Row start -->
            <div class="row gutters">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">Project Image</div>
                        </div>
                        <div class="card-body">
                            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                <ol class="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                </ol>
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img *ngIf="projectDetails.profilePhoto != null"
                                            src='{{endpoint}}/docs/{{projectDetails.id}}/{{projectDetails.profilePhoto}}'
                                            alt="{{endpoint}}/docs/default.png" height="250vh">
                                        <!-- <img *ngIf="projectDetails.profilePhoto === null" src='{{endpoint}}/docs/default.png'
                                        alt="Project profile image" height="250vh"> -->
                                        <img *ngIf="projectDetails.profilePhoto === null"
                                            src="assets/img/aiidc-logo.png" alt="Project profile image" height="250vh"
                                            style="opacity: 0.8;" class="mx-auto d-block">
                                        <!-- <img src="assets/img/img6.jpg" class="rounded d-block w-100" alt="Carousel"> -->
                                    </div>
                                    <!-- <div class="carousel-item">
										<img src="assets/img/img7.jpg" class="rounded d-block w-100" alt="Carousel">
									</div>
									<div class="carousel-item">
										<img src="assets/img/img1.jpg" class="rounded d-block w-100" alt="Carousel">
									</div> -->
                                </div>
                                <!-- <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
									<span class="carousel-control-prev-icon" aria-hidden="true"></span>
									<span class="sr-only">Previous</span>
								</a>
								<a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
									<span class="carousel-control-next-icon" aria-hidden="true"></span>
									<span class="sr-only">Next</span>
								</a> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-12">
                    <div class="card" style="min-height: 365px;">
                        <div class="card-header">
                            <div class="card-title">Basic Details</div>
                            <!-- <div class="card-sub-title-style"><b>Scheme Name: </b><span class="scheme">{{projectDetails?.scheme_name}}</span></div> -->
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <strong>Project Title : </strong>{{projectDetails.name}}
                                </div>
                            </div>
                            <div class="row" style="padding-top: 15px;">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <strong>Description : </strong>{{projectDetails?.description}}
                                </div>
                            </div>
                            <div class="row" style="padding-top: 15px;">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <strong>District : </strong> {{projectDetails.district}}
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <strong>Project Type : </strong> {{projectType}}
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12">
                                    <strong>Source of Fund : </strong> {{projectDetails?.scheme}}
                                </div>
                                <!-- <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                            <strong>Administrative Approval : </strong> {{projectDetails?.aa_status}}
                                        </div> -->
                            </div>
                            <div class="row" style="padding-top: 15px;">
                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                                    <strong>Contractor Name: </strong> {{projectDetails?.contractor_name}}
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <strong>Contractor's Phone : </strong> {{projectDetails?.contractor_phone}}
                                </div>
                            </div>
                            <div class="row" style="padding-top: 15px;"
                                *ngIf="projectDetails.scheme == 'State Government Funded Scheme'">
                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                                    <strong>Contractor Name: </strong> {{projectDetails?.contractor_name_cs}}
                                    <br>
                                    <small>(Consultancy Service)</small>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <strong>Contractor's Phone : </strong> {{projectDetails?.contractor_phone_cs}}
                                    <br>
                                    <small>(Consultancy Service)</small>
                                </div>
                            </div>
                            <div class="row" style="padding-top: 15px;">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                    <strong>Percentage Progress : </strong> {{projectDetails?.percentageProgress}} %
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                    <strong>Financial Progress : </strong> {{projectDetails?.financialProgress}} %
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                    <strong>File No. : </strong> {{projectDetails?.fileNo}}
                                </div>
                            </div>
                            <div class="row" style="padding-top: 15px;"
                                *ngIf="projectDetails.scheme == 'State Government Funded Scheme'">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                    <strong>Percentage Progress : </strong> {{projectDetails?.percentageProgressCs}} %
                                    <br>
                                    <small>(Consultancy Service)</small>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                    <strong>Financial Progress : </strong> {{projectDetails?.financialProgressCs}} %
                                    <br>
                                    <small>(Consultancy Service)</small>
                                </div>
                            </div>
                            <div class="row" style="padding-top: 15px;">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <strong>Remarks : </strong> {{projectDetails?.remarks}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Row end -->

            <!-- Row start -->
            <div class="row gutters">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">Work Order & Other Details</div>
                        </div>
                        <div class="card-body">
                            <div class="row py-2">
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                                    <strong>Work order no. : </strong> {{projectDetails?.wo_no}}
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                                    <strong>Work order amount : </strong> {{ projectDetails?.wo_amount | currency:'INR':
                                    'symbol': '1.2-2': 'en-IN'}}
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
                                    <!-- <strong>Work order date : </strong> {{projectFunds?.aa_date | date : 'dd/MM/yyyy'}} -->
                                    <strong>Work order date : </strong> {{projectDetails?.wo_date | date :
                                    'dd/MMM/yyyy'}}
                                </div>
                            </div>
                            <div class="row py-3">

                                <!-- <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                            <strong>Tender amount : </strong> {{projectDetails?.tender_amount | currency:'INR': 'symbol': '1.2-2': 'en-IN'}}
                                        </div> -->
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                                    <strong>Actual start date : </strong> {{projectDetails?.actual_start | date :
                                    'dd/MMM/yyyy'}}
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                                    <strong>Planned end date : </strong> {{projectDetails?.actual_end | date :
                                    'dd/MMM/yyyy'}}
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
                                    <strong>Completion date : </strong> {{projectDetails?.completion_date | date :
                                    'dd/MMM/yyyy'}}
                                </div>
                            </div>
                            <div class="row border-top py-2"
                                *ngIf="projectDetails.scheme == 'State Government Funded Scheme'">
                                <div class="col-12 cs-style">
                                    Consultancy Service
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12 pt-3">
                                    <strong>Work order no. : </strong> {{projectDetails?.wo_no_cs}}
                                    <br>
                                    <small>(Consultancy Service)</small>
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                                    <strong>Work order amount : </strong> {{projectDetails?.wo_amount_cs}}
                                    <br>
                                    <small>(Consultancy Service)</small>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
                                    <!-- <strong>Work order date : </strong> {{projectFunds?.aa_date | date : 'dd/MM/yyyy'}} -->
                                    <strong>Work order date : </strong> {{projectDetails?.wo_date_cs | date :
                                    'dd/MMM/yyyy'}}
                                    <br>
                                    <small>(Consultancy Service)</small>
                                </div>
                            </div>
                            <div class="row py-2" *ngIf="projectDetails.scheme == 'State Government Funded Scheme'">
                                <!-- <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                            <strong>Tender amount : </strong> {{projectDetails?.tender_amount_cs | currency:'INR': 'symbol': '1.2-2': 'en-IN'}}
                                            <br>
                                            <small>(Consultancy Service)</small>
                                        </div> -->
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                                    <strong>Actual start date : </strong> {{projectDetails?.actual_start_cs | date :
                                    'dd/MMM/yyyy'}}
                                    <br>
                                    <small>(Consultancy Service)</small>
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                                    <strong>Planned end date : </strong> {{projectDetails?.actual_end_cs | date :
                                    'dd/MMM/yyyy'}}
                                    <br>
                                    <small>(Consultancy Service)</small>
                                </div>
                            </div>
                            <div class="row py-2">
                                <!-- <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                            <strong>Estimated cost : </strong> {{projectDetails?.initial_amount | currency:'INR': 'symbol': '1.2-2': 'en-IN'}}
                                        </div> -->
                                <!-- <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                            <strong>Physical progress : </strong> {{projectDetails?.percentageProgress}}
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                            <strong>Financial progress : </strong> {{projectDetails?.financialProgress}}
                                        </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row end -->
        </div>
        <!-- Content wrapper end -->

    </div>
    <!-- ************************* Main container end ************************** -->

    <div class="modal fade" id="editProject" tabindex="-1" role="dialog" aria-labelledby="addNewTaskLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addNewTaskLabel">Edit Project Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row gutters">

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="form-group">
                                <label for="taskTitle">
                                    <h5>Project Title</h5>
                                </label>
                                <input type="text" class="form-control" placeholder="Contractor."
                                    [(ngModel)]="projectDetails.name">
                            </div>
                        </div>

                        <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="taskTitle">
                                    <h5>Scheme Name</h5>
                                </label>
                                <input type="text" class="form-control" placeholder="Scheme Name."
                                    [(ngModel)]="projectDetails.scheme_name">
                            </div>
                        </div> -->

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="taskTitle">
                                    <h5>District Name</h5>
                                </label>
                                <select class="form-control" id="sourceOfFund" [(ngModel)]="projectDetails.district">
                                    <option value="Baksa">Baksa</option>
                                    <option value="Barpeta">Barpeta</option>
                                    <option value="Bongaigaon">Bongaigaon</option>
                                    <option value="Cachar">Cachar</option>
                                    <option value="Charaideo">Charaideo</option>
                                    <option value="Chirang">Chirang</option>
                                    <option value="Darrang">Darrang</option>
                                    <option value="Dhemaji">Dhemaji</option>
                                    <option value="Dhubri">Dhubri</option>
                                    <option value="Dibrugarh">Dibrugarh</option>
                                    <option value="Dima Hasao">Dima Hasao</option>
                                    <option value="Goalpara">Goalpara</option>
                                    <option value="Golaghat">Golaghat</option>
                                    <option value="Hailakandi">Hailakandi</option>
                                    <option value="Jorhat">Jorhat</option>
                                    <option value="Kamrup Metropolitan">Kamrup Metropolitan</option>
                                    <option value="Kamrup">Kamrup</option>
                                    <option value="Karbi Anglong">Karbi Anglong</option>
                                    <option value="Karimganj">Karimganj</option>
                                    <option value="Kokrajhar">Kokrajhar</option>
                                    <option value="Lakhimpur">Lakhimpur</option>
                                    <option value="Majuli">Majuli</option>
                                    <option value="Morigaon">Morigaon</option>
                                    <option value="Nagaon">Nagaon</option>
                                    <option value="Nalbari">Nalbari</option>
                                    <option value="Sivasagar">Sivasagar</option>
                                    <option value="Sonitpur">Sonitpur</option>
                                    <option value="South Salmara-Mankachar">South Salmara-Mankachar</option>
                                    <option value="Tinsukia">Tinsukia</option>
                                    <option value="Udalguri">Udalguri</option>
                                    <option value="West Karbi Anglong">West Karbi Anglong</option>
                                    <option value="Biswanath">Biswanath</option>
                                    <option value="Hojai">Hojai</option>
                                    <option value="Bajali">Bajali</option>
                                    <option value="Tamulpur">Tamulpur</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="taskTitle">
                                    <h5>Contractor Name</h5>
                                </label>
                                <input type="text" class="form-control" placeholder="Contractor."
                                    [(ngModel)]="projectDetails.contractor_name">
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="taskTitle">
                                    <h5>Contractor's Phone</h5>
                                </label>
                                <input type="text" class="form-control" placeholder="Contractor's Phone."
                                    [(ngModel)]="projectDetails.contractor_phone">
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="workOrderDate">
                                    <h5>Work order date</h5>
                                </label>
                                <div class="">
                                    <input type="date" class="form-control"
                                        [ngModel]="projectDetails.wo_date | date:'yyyy-MM-dd'"
                                        (input)="parseWoDate($event.target.value)" />
                                    <!-- <input type="datetime" name="" class="form-control" id="workOrderDate" placeholder="Work order date" [(ngModel)]="projectFunds.aa_date"> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="taskTitle">
                                    <h5>Work order No.</h5>
                                </label>
                                <input type="text" class="form-control" placeholder="Work order No."
                                    [(ngModel)]="projectDetails.wo_no">
                            </div>
                        </div>
                        <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12" *ngIf="projectDetails.scheme == 'State Government Funded Scheme'">
                            <div class="form-group">
                                <label for="packageAmount">
                                    <h5>Work order amount <small>(Consultancy Service)</small></h5>
                                </label>
                                <div class="">
                                    <input type="text" name="" class="form-control" id="packageAmount" placeholder="Work order amount" [(ngModel)]="projectDetails.wo_amount_cs">
                                </div>
                            </div>
                        </div> -->
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="packageAmount">
                                    <h5>Work order amount</h5>
                                </label>
                                <div class="">
                                    <input type="number" name="" class="form-control" id="packageAmount"
                                        placeholder="Work order amount" [(ngModel)]="projectDetails.wo_amount">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="percentageProgress">
                                    <h5>Percentage progress (%)</h5>
                                </label>
                                <input type="number" class="form-control" min="0" max="100"
                                    placeholder="Percentage progress" [(ngModel)]="projectDetails.percentageProgress">
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="financialProgress">
                                    <h5>Financial progress (%)</h5>
                                </label>
                                <input type="number" class="form-control" placeholder="Financial progress"
                                    [(ngModel)]="projectDetails.financialProgress">
                            </div>
                        </div>
                    </div>
                    <div class="row gutters" *ngIf="projectDetails.scheme == 'State Government Funded Scheme'">
                        <div class="col-12 cs-style pb-2">
                            Consultancy Service
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="taskTitle">
                                    <h5>Contractor Name <small>(Consultancy Service)</small></h5>
                                </label>
                                <input type="text" class="form-control" placeholder="Contractor."
                                    [(ngModel)]="projectDetails.contractor_name_cs">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="taskTitle">
                                    <h5>Contractor's Phone <small>(Consultancy Service)</small></h5>
                                </label>
                                <input type="text" class="form-control" placeholder="Contractor's Phone."
                                    [(ngModel)]="projectDetails.contractor_phone_cs">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="workOrderDate">
                                    <h5>Work order date <small>(Consultancy Service)</small></h5>
                                </label>
                                <div class="">
                                    <input type="date" class="form-control"
                                        [ngModel]="projectDetails.wo_date_cs | date:'yyyy-MM-dd'"
                                        (input)="parseWoCsDate($event.target.value)" />
                                    <!-- <input type="datetime" name="" class="form-control" id="workOrderDate" placeholder="Work order date" [(ngModel)]="projectFunds.aa_date"> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="taskTitle">
                                    <h5>Work order No. <small>(Consultancy Service)</small></h5>
                                </label>
                                <input type="text" class="form-control" placeholder="Work order No."
                                    [(ngModel)]="projectDetails.wo_no_cs">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="financialProgress">
                                    <h5>Percentage progress (%) <small>(Consultancy Service)</small></h5>
                                </label>
                                <input type="number" class="form-control" min="0" max="100"
                                    placeholder="Percentage progress" [(ngModel)]="projectDetails.percentageProgressCs">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="financialProgress">
                                    <h5>Financial progress (%) <small>(Consultancy Service)</small></h5>
                                </label>
                                <input type="number" class="form-control" placeholder="Financial progress"
                                    [(ngModel)]="projectDetails.financialProgressCs">
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <div class="form-group">
                                <label for="financialProgress">
                                    <h5>Completion Date</h5>
                                </label>
                                <input type="date" class="form-control"
                                        [ngModel]="projectDetails.completion_date | date:'yyyy-MM-dd'"
                                        (input)="parseWoComplDate($event.target.value)" />
                            </div>
                        </div>
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
                            <div class="form-group">
                                <label for="fileNo">
                                    <h5>File no</h5>
                                </label>
                                <input type="text" class="form-control" placeholder="File Number"
                                    [(ngModel)]="projectDetails.fileNo">
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="form-group">
                                <label for="description">
                                    <h5>Description</h5>
                                </label>
                                <textarea class="form-control" id="description" placeholder="Project description"
                                    [(ngModel)]="projectDetails.description">{{projectDetails.description}}</textarea>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="form-group">
                                <label for="description">
                                    <h5>Remarks</h5>
                                </label>
                                <textarea class="form-control" id="remarks" placeholder="Remarks"
                                    [(ngModel)]="projectDetails.remarks">{{projectDetails.remarks}}</textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer custom">
                    <div class="left-side">
                        <button type="button" class="btn btn-link danger btn-block" data-dismiss="modal">Cancel</button>
                    </div>
                    <div class="divider"></div>
                    <div class="right-side">
                        <button type="button" (click)="updateProjectDetails()"
                            class="btn btn-link success btn-block">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- Container fluid end -->
<!-- <script src="../../../../assets/vendor/datepicker/js/picker.js"></script>
<script src="../../../../assets/vendor/datepicker/js/picker.date.js"></script>
<script src="../../../../assets/vendor/datepicker/js/custom-picker.js"></script> -->