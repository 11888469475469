<div class="bg">
    <!-- Container start -->
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-8">
                
            </div>
            <div class="col-md-4" style="margin-top:10px;border-radius:30px;">
                
                <div class="login-screen">
                    <div class="login-box" style="background: #ffffff; border-radius: 15px;opacity: 0.9;">
                        <div class="row justify-content-center">
                            
                            <a href="#" class="login-logo-1"
                        style="display:flex;align-items: center; font-size: 20px;">
                        <img src="assets/aiidc-logo2.png" alt="AIIDC" />
                    </a>
                        <br><br>
                    </div>
                    <p class="justify-content-md-center pt-2"><strong>Public Works Management System (Public Login)
                        <br>
                        <b>New Registration</b>
                    </strong></p>
                    <br>
                        <div class="form-group">
                            <select class="form-control" name="projectType" [(ngModel)]="userDetails.role">
                                <option class="text-center" selected>- Select User Type -</option>
                                <option class="text-center" value="pub">Public</option>
                                <option class="text-center" value="mla">MLA</option>
                                <option class="text-center" value="minister">Minister</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <input type="text" [(ngModel)]="userDetails.name" class="form-control" placeholder="Name" />
                        </div>
                        <div class="form-group">
                            <input type="text" [(ngModel)]="userDetails.address" class="form-control" maxlength="10"
                                placeholder="Address" />
                        </div>
                        <div class="form-group">
                            <input type="email" [(ngModel)]="userDetails.email" class="form-control"
                                placeholder="Email" />
                        </div>
                        <div class="form-group">
                            <input type="number" [(ngModel)]="userDetails.phone" class="form-control" maxlength="10"
                                placeholder="Phone" />
                        </div>
                        <div class="row" *ngIf="showOTPInput">
                            <div class="col-lg-12">
                                <label for="otp">Please enter the OTP</label>
                            </div>
                        </div>
                        <div class="row" *ngIf="showOTPInput">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding:0px;">
                                <div class="form-group">
                                    <input type="number" [(ngModel)]="OTP.otp1" class="form-control" maxlength="1" />
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding:0px;">
                                <div class="form-group">
                                    <input type="number" [(ngModel)]="OTP.otp2" class="form-control" maxlength="1" />
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding:0px;">
                                <div class="form-group">
                                    <input type="number" [(ngModel)]="OTP.otp3" class="form-control" maxlength="1" />
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding:0px;">
                                <div class="form-group">
                                    <input type="number" [(ngModel)]="OTP.otp4" class="form-control" maxlength="1" />
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding:0px;">
                                <div class="form-group">
                                    <input type="number" [(ngModel)]="OTP.otp5" class="form-control" maxlength="1" />
                                </div>
                            </div>

                        </div>
                        <div class="actions mb-4">
                            <button (click)="cancelRegistration()" class="btn btn-warning">Cancel</button>
                            <button (click)="next()" *ngIf="!showOTPInput" class="btn btn-danger"
                                [disabled]="isRegister">Next</button>
                            <button (click)="verifyOtpData()" *ngIf="showOTPInput" class="btn btn-danger"
                                [disabled]="isRegister">Register Me</button>

                        </div>
                        <p>Already registered? &nbsp; <a [routerLink]="['/public-login']"
                                style="color:rgb(13, 130, 233)"><strong>Login</strong></a></p>
                        <hr>
                        <a href="#" class="">
                            <p style="font-size:0.8em;color: #544e48; margin-left: 12px"><strong>Powered by</strong><img
                                    src="assets/skaplink.png" alt="AIIDC"
                                    style="height: 15px; width: 180px; margin-right:28px; margin-right: 14px;"
                                    align="right"></p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- </form> -->
    </div>
    <!-- Container end -->
</div>