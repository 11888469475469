<div class="container-fluid">
    <div class="main-container">
        <div class="content-wrapper">
            <div>
                <h2 style="text-align: center;">Feedbacks</h2>
            </div>
            <div>
                <button data-toggle="modal" data-target="#addFeedback" data-placement="top" title=""
                    data-original-title="Add Feedback" style="text-align: right;" type="button"
                    class="btn btn-primary"><i class="icon-add"></i>Feedback</button>
            </div>
            <div class="list">
                <div *ngFor="let feedback of overallFeedbacks">

                    <div class="feedbackContainer">
                        <div class="row">
                            <div class="col-lg-1">
                                <img src="{{feedback.user.avatar}}" alt="Profile" />
                            </div>
                            <div class="col-lg-11">
                                <p>{{feedback.feedback}}</p>
                                <p><strong>{{feedback.user.name}}</strong>&nbsp;&nbsp;&nbsp;{{feedback.createdAt | date:'medium'}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addFeedback" tabindex="-1" role="dialog" aria-labelledby="Feedback" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" id="addNewTaskLabel">Add Feedback</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="feedbackInput">Your Feedback</label>
                    <textarea class="form-control" id="feedbackInput" rows="5" [(ngModel)]="feedback"></textarea>
                </div>
            </div>
            <div class="modal-footer custom">
                <div class="left-side">
                    <button type="button" class="btn btn-link danger btn-block" data-dismiss="modal">Cancel</button>
                </div>
                <div class="divider"></div>
                <div class="right-side">
                    <button type="button" [disabled]="false" class="btn btn-link success btn-block"
                        (click)="submitFeedback()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>