<!-- Loading starts -->
<div id="loading-wrapper" *ngIf="isLoading">
	<!-- <div class="spinner-border" role="status">
		<span class="sr-only">Loading...</span>
	</div> -->
	<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>
<!-- Loading ends -->


<!-- Container fluid start -->
<div class="container-fluid p-0" *ngIf="!isLoading">
	<app-project-navbar projectId='{{projectId}}' activePage='costDisbursement'></app-project-navbar>

	<!-- ************************* Main container start ************* ************* -->
	<div class="main-container" *ngIf="kw.accessKeyword == projectObject['project'].accessKeyword || kw.role == 'su' || kw.role == 'sv'">
		<!-- Page header start -->
		<div class="page-header ">
			<div class="row">
				<div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-6 text-center">
					<p class="fontmobile" style="text-align: left;">{{projectDetails.name}}</p>
				</div>
				<div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6 text-center" *ngIf="permission.projectUpdationPrivilage">
					<a style="color: white;" href="#" data-toggle="modal" data-target="#addModal"  data-placement="top">+ Add Cost</a>
				</div>
			</div>

		</div>
		<!-- Page header end -->
		<!-- Content wrapper start -->
		<div class="content-wrapper">
			

			<!-- Row start -->
			<!-- <div class="row gutters">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
							<div class="card">
								<div class="card-header">
									<div class="card-title">Cost Project Image</div>
								</div>
								<div class="card-body">
								</div>
							</div>
						</div>
						<div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-12">
							<div class="card" style="min-height: 365px;">
								<div class="card-header">
									<div class="card-title">Basic Details</div>
								</div>
								<div class="card-body">
								</div>
							</div>
						</div>

					</div> -->
			<!-- Row end -->
			<div mk-morris-js [options]="chartAreaOptions" [data]="disbursementGraphData" type="Bar"
				style="height: 200px; width: 100%;"></div>
				<div class="table-responsive">
					<table class="table" >
						<thead>
							<tr>
								<th class="fontthead">Serial</th>
								<th class="fontthead">Date(YYYY-MM-DD)</th>
								<th class="fontthead">Amount</th>
								<th class="fontthead">Note</th>
								<th class="fontthead">Actions</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let data of tableData; let ndx = index">
								<td> {{ ndx+1 }} </td>
								<td> {{ data.disbursement_date }} </td>
								<td> {{ data.disbursement_amount }} </td>
								<td> {{ data.note }} </td>
								<td> 
									<i class="icon-border_color btn btn-info" data-toggle="modal" data-target="#editModal" ></i>&nbsp;
									<i class="icon-delete btn btn-danger" (click)="onDeleteConfirm(data.id)"></i></td>
							</tr>
						</tbody>
					</table>
				  </div>
			<br>
			<strong>Remaining Balance : {{TotalAmount - ReceiveAmount}}</strong>
			<!-- Row start -->
			<!-- <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div class="card">
								<div class="card-header">
									<div class="card-title">Other Details</div>
								</div>
								<div class="card-body">
								</div>
							</div>
						</div>
					</div> -->
			<!-- Row end -->

		</div>
		<br>
		<!-- Content wrapper end -->

	</div>
	<!-- ************************* Main container end ************************** -->

</div>


<!-- modal cost db add -->

<div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="Upload Document"
	aria-hidden="true">
	<div class="modal-dialog modal-md" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="addNewTaskLabel">Add Cost</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>

			<div class="modal-body">
				<div class="row gutters">
					<!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
						<label for="serial" class="float-right" style="font-weight: bolder; font-size: large;"> Serial:
						</label>
					</div>
					<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
						<input type="number" name="" id="" style="width:100%">
					</div>
					<br><br><br> -->
					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
						<label for="serial" class="float-right" style="font-weight: bolder; font-size: large;"> Date:
						</label>
					</div>
					<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
						<input type="date" name="" id="" style="width:100%" [(ngModel)]="costProject.date">
					</div>
					<br><br><br>
					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
						<label for="Amount" class="float-right" style="font-weight: bolder; font-size: large;"> Amount:
						</label>
					</div>
					<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
						<input type="number" name="" id="" style="width:100%" [(ngModel)]="costProject.amount"> 
					</div>
					<br><br><br>
					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
						<label for="Note" class="float-right" style="font-weight: bolder; font-size: large;"> Note:
						</label>
					</div>
					<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
						<input type="text" name="" id="" style="width:100%" [(ngModel)]="costProject.note">
					</div>
				</div>
			</div>
			<div class="modal-footer custom">
				<div class="left-side">
					<button type="button" class="btn btn-link danger btn-block" data-dismiss="modal">Cancel</button>
				</div>
				<div class="divider"></div>
				<div class="right-side">
					<button type="button" class="btn btn-link success btn-block" id="addDisbursement" (click)="addDisbursementRecord()" [disabled]="addCostDisable">Add 
						&nbsp;&nbsp;
						<div *ngIf="addCostDisable" class="spinner-border text-dark spinner-border-sm" role="status">
						<span class="sr-only">Loading...</span>
					  </div>
					</button>
				</div>
			</div>
			<!-- </form> -->
		</div>
	</div>
</div>

<!-- modal cost db Edit -->

<div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="Upload Document"
	aria-hidden="true">
	<div class="modal-dialog modal-md" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="addNewTaskLabel">Edit Cost</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>

			<div class="modal-body">
				<div class="row gutters">
					<!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
						<label for="serial" class="float-right" style="font-weight: bolder; font-size: large;"> Serial:
						</label>
					</div>
					<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
						<input type="text" name="" id="" style="width:100%" [(ngModel)]="costProject.serialNo">
					</div>
					<br><br><br> -->
					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
						<label for="date" class="float-right" style="font-weight: bolder; font-size: large;"> Date:
						</label>
					</div>
					<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
						<input type="date" name="" id="" style="width:100%" [(ngModel)]="costProject.date">
					</div>
					<br><br><br>
					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
						<label for="amount" class="float-right" style="font-weight: bolder; font-size: large;"> Amount:
						</label>
					</div>
					<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
						<input type="number" name="" id="" style="width:100%" [(ngModel)]="costProject.amount">
					</div>
					<br><br><br>
					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
						<label for="note" class="float-right" style="font-weight: bolder; font-size: large;"> Note:
						</label>
					</div>
					<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
						<input type="text" name="" id="" style="width:100%" [(ngModel)]="costProject.note">
					</div>
				</div>
			</div>
			<div class="modal-footer custom">
				<div class="left-side">
					<button type="button" class="btn btn-link danger btn-block" data-dismiss="modal">Cancel</button>
				</div>
				<div class="divider"></div>
				<div class="right-side">
					<button type="button" class="btn btn-link success btn-block">Add</button>
				</div>
			</div>
			<!-- </form> -->
		</div>
	</div>
</div>