<!-- Loading starts -->
<div id="loading-wrapper" *ngIf="isLoading">
    <!-- <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div> -->
    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>
<!-- Loading ends --> 


<!-- Container fluid start -->
<div class="container-fluid p-0" *ngIf="!isLoading">
    <app-project-navbar projectId='{{projectId}}' activePage='measurementBook'></app-project-navbar>
    <!-- ************************* Main container start ************************** -->
    <div class="main-container" *ngIf="kw.accessKeyword === projectObject['project'].accessKeyword || kw.role != 'citizen' || kw.role === 'su'">

        <!-- Page header start -->
        <div class="page-header" >
            <div class="row">
                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-6 text-center">
                    <p  class="fontmobile"  style="text-align: left;">{{projectDetails.name}}</p>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6 text-center" style="padding-right:0px;" *ngIf="permission.uploadDocument">
                            <a style="color: white;" href="#" data-toggle="modal" data-target="#uploadDocument" data-placement="top" title="" data-original-title="New File">
                            <i class="icon-plus"></i> &nbsp;New MB File
                            </a>
                </div>
            </div>
        </div>
        <!-- Page header end -->

        <!-- Content wrapper start -->
        <div class="content-wrapper" >
            
            <!-- Row start -->
                <div class="row gutters">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="documents-section">
                            <!-- Row start -->
                            <div class="row no-gutters">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 docs-type-container" style="padding-top:0px;">
                                    <div class="documents-container" style="overflow: scroll !important; height: inherit">
                                        <div class="tasksContainerScroll">
                                            <div class="documents-body">
                                                <!-- Row start -->
                                                <span *ngIf="mbGallery.length!==0">
                                                <div class="row gutters">
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12" *ngFor="let doc of mbGallery">
                                                        <div class="doc-block">
                                                            <div class="doc-icon">
                                                                <img src="assets/img/docs/xls.svg" alt="Doc Icon" />
                                                            </div>
                                                            <div class="doc-title">{{doc.title}}</div>
                                                            <a href="{{endpoint}}/docs/{{projectId}}/{{doc.original_file_name}}" target="_blank" class="btn btn-primary btn-lg">Download</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                </span>
                                                <!-- Row end -->
                                                <!-- Row start -->
                                                <span *ngIf="mbGallery.length===0">
                                                    <div class="row gutters text-center">
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
                                                            <h5 class="text-center" style="color:gray">No MB file found</h5>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Row end -->
                        </div>
                    </div>
                </div>
            <!-- Row end -->
        </div>
        <!-- Content wrapper end -->

    </div>
    <!-- ************************* Main container end ************************** -->


    <!-- Upload file modal -->
    <div class="modal fade" id="uploadDocument" tabindex="-1" role="dialog" aria-labelledby="Upload Document" aria-hidden="true">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addNewTaskLabel">Upload MB</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!-- <form name="uploadForm" enctype="multipart/form-data"> -->
                    <div class="modal-body">
                        <div class="row gutters">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="mbTitle">MB Title</label>
                                    <input type="text" class="form-control" id="mbTitle" maxlength="50" [(ngModel)]="uploadDocObject.mbTitle" placeholder="MB Title">
                                    <span style="color:gray">*Max character = 50</span>
                                </div>
                            </div>
                            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12">
                                <label for="file" style="margin-top:8px;">File</label>
                            </div>
                            <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-12">
                                <div class="form-group">
                                    <label class="custom-file-label" for="file">{{chooseFile}}</label>
                                    <input type="file" class="custom-file-input" aria-describedby="inputGroupFileAddon01" name="file" id="file"
                                        (change)="onFileSelect($event)">
                                        <span style="color:gray">*Only .doc, .docx, .xls, .xlsx, jpg, png and jpeg files are allowed</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer custom">
                        <div class="left-side">
                            <button type="button" class="btn btn-link danger btn-block" data-dismiss="modal">Cancel</button>
                        </div>
                        <div class="divider"></div>
                        <div class="right-side">
                            <button type="button" (click)="handleUploadDocument()" class="btn btn-link success btn-block">Upload</button>
                        </div>
                    </div>
                <!-- </form> -->
            </div>
        </div>
    </div>
    <!-- end of upload file -->

</div>
<!-- Container fluid end -->
